import Avatar from 'components/dist/atoms/Avatar';
import Button from 'components/dist/atoms/Button';
import Checkbox from 'components/dist/atoms/Checkbox';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Label from 'components/dist/atoms/Label';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { ExclamationCircleGraphic } from 'src/icons/exclamation-circle-graphic';
import { classNames } from 'src/utils/tailwind-class-names';

import { UserListItem } from '../user-list-item';
import { useTeamMemberSelectList } from './team-member-select-list.hook';
import { TeamMemberSelectListProps } from './team-member-select-list.types';

export const TeamMemberSelectList = ({
    assignedList = [],
    ...props
}: TeamMemberSelectListProps) => {
    const state = useTeamMemberSelectList({ assignedList, ...props });

    return (<Stack space="md"
        className='px-4 w-full overflow-hidden relative'>
        {state.isLender && <Input
            value={state.searchQuery}
            onChange={state.onSearchChange}
            className="py-1 h-10"
            placeholder="Search"
            wrapClassName='bg-white h-10 sticky top-0' >
            <Input.Prefix>
                <Icon name="Search" className="text-black-primary" width={18} height={18} strokeWidth={2} />
            </Input.Prefix>
            {state.searchQuery && <Input.Suffix>
                <Button
                    variant='ghost'
                    className='p-1'
                    onClick={state.onClearSearchClick}>
                    <Icon name="Cancel" width={24} height={24} strokeWidth={1.5} />
                </Button>
            </Input.Suffix>}
        </Input>}
        <Stack space="md" className={classNames('flex flex-col', {
            'h-[400px] overflow-y-auto scrollbar-stable overflow-x-hidden': !props.autoHeight
        })}>
            {typeof props.unassignedChecked !== 'undefined' && <>
                <Stack space='md' >
                    <Stack space="md" className='overflow-hidden' row items='center'>
                        <Checkbox
                            size="sm"
                            checked={state.isUnAssignedChecked}
                            onCheckedChange={(checked) => state.onUnAssignedCheckChanged(checked === true)}
                            value='unassigned'
                            className='mt-1' />
                        <Avatar
                            size="xs"
                        >
                            <Avatar.Fallback className='bg-gray-neutral-30'>
                                <Icon name="User" width={18} height={18} strokeWidth={1.5} />
                            </Avatar.Fallback>
                        </Avatar>
                        <div className='flex-1 overflow-hidden'>
                            <Text size='sm'>Unassigned</Text>
                        </div>
                    </Stack>
                </Stack>
                <Separator />
            </>}
            {props.selectAllEnabled && <>
                <Stack space='md' >
                    <Stack space="md" className='overflow-hidden' row items='center'>
                        <Label className='flex flex-row items-center gap-2'>
                            <Checkbox
                                size="sm"

                                onCheckedChange={(checked) => {
                                    if (checked === true) {
                                        state.teams.forEach(team => {
                                            state.onCheckboxChange(true, team.users);
                                        });
                                    } else {
                                        state.onUnSelectAllClick();
                                    }
                                }}
                            />
                            <Text size='sm' truncate>Select All</Text>
                        </Label>
                    </Stack>
                </Stack>
                <Separator />
            </>}
            {state.teams
                .map(team => <Stack space='md' key={team.title}>
                    <Text size="sm" className="text-black-primary font-medium">{team.title}</Text>
                    {team.users.map(user => <UserListItem
                        onCheckedChange={(checked) => state.onCheckboxChange(checked, [user])}
                        checked={user.assignedType}
                        user={user}
                        loanEntities={props.loanEntities}
                        loanRoles={props.loanRoles}
                        disabled={user.id === state.loggedInUserId && !state.isLender}
                        role={user.role}
                        key={user.id} />
                    )}
                </Stack>)}
            {state.teams.length === 0 && <div className='flex justify-center flex-col gap-6 items-center flex-1'>
                <ExclamationCircleGraphic />
                <Text className="text-black-primary font-medium" size="sm">
                    No results match your search
                </Text>
            </div>}
        </Stack>
    </Stack>
    )
}