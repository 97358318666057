import { j as y } from "./jsx-runtime-B6kdoens.js";
import { f as Me, g as De, a as _e, b as Le } from "./index-BxpO1vOn.js";
import * as l from "react";
import { $ as I } from "./index-B--ic68G.js";
import { B as Ke } from "./Button-DhTfdjdw.js";
import { D as pe } from "./Dialog.component-Ct1vVnk2.js";
import { I as Fe } from "./Icon-XRrXcH6L.js";
import { c as _ } from "./utils-CJ9afRe1.js";
var ve = 1, qe = 0.9, Pe = 0.8, Ve = 0.17, Y = 0.1, Q = 0.999, Ge = 0.9999, He = 0.99, ze = /[\\\/_+.#"@\[\(\{&]/, Be = /[\\\/_+.#"@\[\(\{&]/g, Oe = /[\s-]/, be = /[\s-]/g;
function ee(e, n, t, s, o, i, d) {
  if (i === n.length) return o === e.length ? ve : He;
  var p = `${o},${i}`;
  if (d[p] !== void 0) return d[p];
  for (var g = s.charAt(i), f = t.indexOf(g, o), c = 0, v, R, E, C; f >= 0; ) v = ee(e, n, t, s, f + 1, i + 1, d), v > c && (f === o ? v *= ve : ze.test(e.charAt(f - 1)) ? (v *= Pe, E = e.slice(o, f - 1).match(Be), E && o > 0 && (v *= Math.pow(Q, E.length))) : Oe.test(e.charAt(f - 1)) ? (v *= qe, C = e.slice(o, f - 1).match(be), C && o > 0 && (v *= Math.pow(Q, C.length))) : (v *= Ve, o > 0 && (v *= Math.pow(Q, f - o))), e.charAt(f) !== n.charAt(i) && (v *= Ge)), (v < Y && t.charAt(f - 1) === s.charAt(i + 1) || s.charAt(i + 1) === s.charAt(i) && t.charAt(f - 1) !== s.charAt(i)) && (R = ee(e, n, t, s, f + 1, i + 2, d), R * Y > v && (v = R * Y)), v > c && (c = v), f = t.indexOf(g, f + 1);
  return d[p] = c, c;
}
function he(e) {
  return e.toLowerCase().replace(be, " ");
}
function Ue(e, n, t) {
  return e = t && t.length > 0 ? `${e + " " + t.join(" ")}` : e, ee(e, n, he(e), he(n), 0, 0, {});
}
var q = '[cmdk-group=""]', Z = '[cmdk-group-items=""]', We = '[cmdk-group-heading=""]', re = '[cmdk-item=""]', ge = `${re}:not([aria-disabled="true"])`, te = "cmdk-item-select", A = "data-value", Te = (e, n, t) => Ue(e, n, t), xe = l.createContext(void 0), V = () => l.useContext(xe), ye = l.createContext(void 0), ne = () => l.useContext(ye), we = l.createContext(void 0), ke = l.forwardRef((e, n) => {
  let t = L(() => {
    var r, u;
    return { search: "", value: (u = (r = e.value) != null ? r : e.defaultValue) != null ? u : "", filtered: { count: 0, items: /* @__PURE__ */ new Map(), groups: /* @__PURE__ */ new Set() } };
  }), s = L(() => /* @__PURE__ */ new Set()), o = L(() => /* @__PURE__ */ new Map()), i = L(() => /* @__PURE__ */ new Map()), d = L(() => /* @__PURE__ */ new Set()), p = Ee(e), { label: g, children: f, value: c, onValueChange: v, filter: R, shouldFilter: E, loop: C, disablePointerSelection: G = !1, vimBindings: $ = !0, ...H } = e, ae = l.useId(), oe = l.useId(), ue = l.useId(), N = l.useRef(null), b = at();
  M(() => {
    if (c !== void 0) {
      let r = c.trim();
      t.current.value = r, k.emit();
    }
  }, [c]), M(() => {
    b(6, ce);
  }, []);
  let k = l.useMemo(() => ({ subscribe: (r) => (d.current.add(r), () => d.current.delete(r)), snapshot: () => t.current, setState: (r, u, m) => {
    var a, h, x;
    if (!Object.is(t.current[r], u)) {
      if (t.current[r] = u, r === "search") T(), U(), b(1, W);
      else if (r === "value" && (m || b(5, ce), ((a = p.current) == null ? void 0 : a.value) !== void 0)) {
        let S = u ?? "";
        (x = (h = p.current).onValueChange) == null || x.call(h, S);
        return;
      }
      k.emit();
    }
  }, emit: () => {
    d.current.forEach((r) => r());
  } }), []), O = l.useMemo(() => ({ value: (r, u, m) => {
    var a;
    u !== ((a = i.current.get(r)) == null ? void 0 : a.value) && (i.current.set(r, { value: u, keywords: m }), t.current.filtered.items.set(r, ie(u, m)), b(2, () => {
      U(), k.emit();
    }));
  }, item: (r, u) => (s.current.add(r), u && (o.current.has(u) ? o.current.get(u).add(r) : o.current.set(u, /* @__PURE__ */ new Set([r]))), b(3, () => {
    T(), U(), t.current.value || W(), k.emit();
  }), () => {
    i.current.delete(r), s.current.delete(r), t.current.filtered.items.delete(r);
    let m = K();
    b(4, () => {
      T(), (m == null ? void 0 : m.getAttribute("id")) === r && W(), k.emit();
    });
  }), group: (r) => (o.current.has(r) || o.current.set(r, /* @__PURE__ */ new Set()), () => {
    i.current.delete(r), o.current.delete(r);
  }), filter: () => p.current.shouldFilter, label: g || e["aria-label"], disablePointerSelection: G, listId: ae, inputId: ue, labelId: oe, listInnerRef: N }), []);
  function ie(r, u) {
    var m, a;
    let h = (a = (m = p.current) == null ? void 0 : m.filter) != null ? a : Te;
    return r ? h(r, t.current.search, u) : 0;
  }
  function U() {
    if (!t.current.search || p.current.shouldFilter === !1) return;
    let r = t.current.filtered.items, u = [];
    t.current.filtered.groups.forEach((a) => {
      let h = o.current.get(a), x = 0;
      h.forEach((S) => {
        let j = r.get(S);
        x = Math.max(j, x);
      }), u.push([a, x]);
    });
    let m = N.current;
    F().sort((a, h) => {
      var x, S;
      let j = a.getAttribute("id"), z = h.getAttribute("id");
      return ((x = r.get(z)) != null ? x : 0) - ((S = r.get(j)) != null ? S : 0);
    }).forEach((a) => {
      let h = a.closest(Z);
      h ? h.appendChild(a.parentElement === h ? a : a.closest(`${Z} > *`)) : m.appendChild(a.parentElement === m ? a : a.closest(`${Z} > *`));
    }), u.sort((a, h) => h[1] - a[1]).forEach((a) => {
      let h = N.current.querySelector(`${q}[${A}="${encodeURIComponent(a[0])}"]`);
      h == null || h.parentElement.appendChild(h);
    });
  }
  function W() {
    let r = F().find((m) => m.getAttribute("aria-disabled") !== "true"), u = r == null ? void 0 : r.getAttribute(A);
    k.setState("value", u || void 0);
  }
  function T() {
    var r, u, m, a;
    if (!t.current.search || p.current.shouldFilter === !1) {
      t.current.filtered.count = s.current.size;
      return;
    }
    t.current.filtered.groups = /* @__PURE__ */ new Set();
    let h = 0;
    for (let x of s.current) {
      let S = (u = (r = i.current.get(x)) == null ? void 0 : r.value) != null ? u : "", j = (a = (m = i.current.get(x)) == null ? void 0 : m.keywords) != null ? a : [], z = ie(S, j);
      t.current.filtered.items.set(x, z), z > 0 && h++;
    }
    for (let [x, S] of o.current) for (let j of S) if (t.current.filtered.items.get(j) > 0) {
      t.current.filtered.groups.add(x);
      break;
    }
    t.current.filtered.count = h;
  }
  function ce() {
    var r, u, m;
    let a = K();
    a && (((r = a.parentElement) == null ? void 0 : r.firstChild) === a && ((m = (u = a.closest(q)) == null ? void 0 : u.querySelector(We)) == null || m.scrollIntoView({ block: "nearest" })), a.scrollIntoView({ block: "nearest" }));
  }
  function K() {
    var r;
    return (r = N.current) == null ? void 0 : r.querySelector(`${re}[aria-selected="true"]`);
  }
  function F() {
    var r;
    return Array.from((r = N.current) == null ? void 0 : r.querySelectorAll(ge));
  }
  function J(r) {
    let u = F()[r];
    u && k.setState("value", u.getAttribute(A));
  }
  function X(r) {
    var u;
    let m = K(), a = F(), h = a.findIndex((S) => S === m), x = a[h + r];
    (u = p.current) != null && u.loop && (x = h + r < 0 ? a[a.length - 1] : h + r === a.length ? a[0] : a[h + r]), x && k.setState("value", x.getAttribute(A));
  }
  function de(r) {
    let u = K(), m = u == null ? void 0 : u.closest(q), a;
    for (; m && !a; ) m = r > 0 ? nt(m, q) : lt(m, q), a = m == null ? void 0 : m.querySelector(ge);
    a ? k.setState("value", a.getAttribute(A)) : X(r);
  }
  let se = () => J(F().length - 1), me = (r) => {
    r.preventDefault(), r.metaKey ? se() : r.altKey ? de(1) : X(1);
  }, fe = (r) => {
    r.preventDefault(), r.metaKey ? J(0) : r.altKey ? de(-1) : X(-1);
  };
  return l.createElement(I.div, { ref: n, tabIndex: -1, ...H, "cmdk-root": "", onKeyDown: (r) => {
    var u;
    if ((u = H.onKeyDown) == null || u.call(H, r), !r.defaultPrevented) switch (r.key) {
      case "n":
      case "j": {
        $ && r.ctrlKey && me(r);
        break;
      }
      case "ArrowDown": {
        me(r);
        break;
      }
      case "p":
      case "k": {
        $ && r.ctrlKey && fe(r);
        break;
      }
      case "ArrowUp": {
        fe(r);
        break;
      }
      case "Home": {
        r.preventDefault(), J(0);
        break;
      }
      case "End": {
        r.preventDefault(), se();
        break;
      }
      case "Enter":
        if (!r.nativeEvent.isComposing && r.keyCode !== 229) {
          r.preventDefault();
          let m = K();
          if (m) {
            let a = new Event(te);
            m.dispatchEvent(a);
          }
        }
    }
  } }, l.createElement("label", { "cmdk-label": "", htmlFor: O.inputId, id: O.labelId, style: ut }, g), B(e, (r) => l.createElement(ye.Provider, { value: k }, l.createElement(xe.Provider, { value: O }, r))));
}), Je = l.forwardRef((e, n) => {
  var t, s;
  let o = l.useId(), i = l.useRef(null), d = l.useContext(we), p = V(), g = Ee(e), f = (s = (t = g.current) == null ? void 0 : t.forceMount) != null ? s : d == null ? void 0 : d.forceMount;
  M(() => {
    if (!f) return p.item(o, d == null ? void 0 : d.id);
  }, [f]);
  let c = Se(o, i, [e.value, e.children, i], e.keywords), v = ne(), R = D((b) => b.value && b.value === c.current), E = D((b) => f || p.filter() === !1 ? !0 : b.search ? b.filtered.items.get(o) > 0 : !0);
  l.useEffect(() => {
    let b = i.current;
    if (!(!b || e.disabled)) return b.addEventListener(te, C), () => b.removeEventListener(te, C);
  }, [E, e.onSelect, e.disabled]);
  function C() {
    var b, k;
    G(), (k = (b = g.current).onSelect) == null || k.call(b, c.current);
  }
  function G() {
    v.setState("value", c.current, !0);
  }
  if (!E) return null;
  let { disabled: $, value: H, onSelect: ae, forceMount: oe, keywords: ue, ...N } = e;
  return l.createElement(I.div, { ref: P([i, n]), ...N, id: o, "cmdk-item": "", role: "option", "aria-disabled": !!$, "aria-selected": !!R, "data-disabled": !!$, "data-selected": !!R, onPointerMove: $ || p.disablePointerSelection ? void 0 : G, onClick: $ ? void 0 : C }, e.children);
}), Xe = l.forwardRef((e, n) => {
  let { heading: t, children: s, forceMount: o, ...i } = e, d = l.useId(), p = l.useRef(null), g = l.useRef(null), f = l.useId(), c = V(), v = D((E) => o || c.filter() === !1 ? !0 : E.search ? E.filtered.groups.has(d) : !0);
  M(() => c.group(d), []), Se(d, p, [e.value, e.heading, g]);
  let R = l.useMemo(() => ({ id: d, forceMount: o }), [o]);
  return l.createElement(I.div, { ref: P([p, n]), ...i, "cmdk-group": "", role: "presentation", hidden: v ? void 0 : !0 }, t && l.createElement("div", { ref: g, "cmdk-group-heading": "", "aria-hidden": !0, id: f }, t), B(e, (E) => l.createElement("div", { "cmdk-group-items": "", role: "group", "aria-labelledby": t ? f : void 0 }, l.createElement(we.Provider, { value: R }, E))));
}), Ye = l.forwardRef((e, n) => {
  let { alwaysRender: t, ...s } = e, o = l.useRef(null), i = D((d) => !d.search);
  return !t && !i ? null : l.createElement(I.div, { ref: P([o, n]), ...s, "cmdk-separator": "", role: "separator" });
}), Qe = l.forwardRef((e, n) => {
  let { onValueChange: t, ...s } = e, o = e.value != null, i = ne(), d = D((c) => c.search), p = D((c) => c.value), g = V(), f = l.useMemo(() => {
    var c;
    let v = (c = g.listInnerRef.current) == null ? void 0 : c.querySelector(`${re}[${A}="${encodeURIComponent(p)}"]`);
    return v == null ? void 0 : v.getAttribute("id");
  }, []);
  return l.useEffect(() => {
    e.value != null && i.setState("search", e.value);
  }, [e.value]), l.createElement(I.input, { ref: n, ...s, "cmdk-input": "", autoComplete: "off", autoCorrect: "off", spellCheck: !1, "aria-autocomplete": "list", role: "combobox", "aria-expanded": !0, "aria-controls": g.listId, "aria-labelledby": g.labelId, "aria-activedescendant": f, id: g.inputId, type: "text", value: o ? e.value : d, onChange: (c) => {
    o || i.setState("search", c.target.value), t == null || t(c.target.value);
  } });
}), Ze = l.forwardRef((e, n) => {
  let { children: t, label: s = "Suggestions", ...o } = e, i = l.useRef(null), d = l.useRef(null), p = V();
  return l.useEffect(() => {
    if (d.current && i.current) {
      let g = d.current, f = i.current, c, v = new ResizeObserver(() => {
        c = requestAnimationFrame(() => {
          let R = g.offsetHeight;
          f.style.setProperty("--cmdk-list-height", R.toFixed(1) + "px");
        });
      });
      return v.observe(g), () => {
        cancelAnimationFrame(c), v.unobserve(g);
      };
    }
  }, []), l.createElement(I.div, { ref: P([i, n]), ...o, "cmdk-list": "", role: "listbox", "aria-label": s, id: p.listId }, B(e, (g) => l.createElement("div", { ref: P([d, p.listInnerRef]), "cmdk-list-sizer": "" }, g)));
}), et = l.forwardRef((e, n) => {
  let { open: t, onOpenChange: s, overlayClassName: o, contentClassName: i, container: d, ...p } = e;
  return l.createElement(Me, { open: t, onOpenChange: s }, l.createElement(De, { container: d }, l.createElement(_e, { "cmdk-overlay": "", className: o }), l.createElement(Le, { "aria-label": e.label, "cmdk-dialog": "", className: i }, l.createElement(ke, { ref: n, ...p }))));
}), tt = l.forwardRef((e, n) => D((t) => t.filtered.count === 0) ? l.createElement(I.div, { ref: n, ...e, "cmdk-empty": "", role: "presentation" }) : null), rt = l.forwardRef((e, n) => {
  let { progress: t, children: s, label: o = "Loading...", ...i } = e;
  return l.createElement(I.div, { ref: n, ...i, "cmdk-loading": "", role: "progressbar", "aria-valuenow": t, "aria-valuemin": 0, "aria-valuemax": 100, "aria-label": o }, B(e, (d) => l.createElement("div", { "aria-hidden": !0 }, d)));
}), w = Object.assign(ke, { List: Ze, Item: Je, Input: Qe, Group: Xe, Separator: Ye, Dialog: et, Empty: tt, Loading: rt });
function nt(e, n) {
  let t = e.nextElementSibling;
  for (; t; ) {
    if (t.matches(n)) return t;
    t = t.nextElementSibling;
  }
}
function lt(e, n) {
  let t = e.previousElementSibling;
  for (; t; ) {
    if (t.matches(n)) return t;
    t = t.previousElementSibling;
  }
}
function Ee(e) {
  let n = l.useRef(e);
  return M(() => {
    n.current = e;
  }), n;
}
var M = typeof window > "u" ? l.useEffect : l.useLayoutEffect;
function L(e) {
  let n = l.useRef();
  return n.current === void 0 && (n.current = e()), n;
}
function P(e) {
  return (n) => {
    e.forEach((t) => {
      typeof t == "function" ? t(n) : t != null && (t.current = n);
    });
  };
}
function D(e) {
  let n = ne(), t = () => e(n.snapshot());
  return l.useSyncExternalStore(n.subscribe, t, t);
}
function Se(e, n, t, s = []) {
  let o = l.useRef(), i = V();
  return M(() => {
    var d;
    let p = (() => {
      var f;
      for (let c of t) {
        if (typeof c == "string") return c.trim();
        if (typeof c == "object" && "current" in c) return c.current ? (f = c.current.textContent) == null ? void 0 : f.trim() : o.current;
      }
    })(), g = s.map((f) => f.trim());
    i.value(e, p, g), (d = n.current) == null || d.setAttribute(A, p), o.current = p;
  }), o;
}
var at = () => {
  let [e, n] = l.useState(), t = L(() => /* @__PURE__ */ new Map());
  return M(() => {
    t.current.forEach((s) => s()), t.current = /* @__PURE__ */ new Map();
  }, [e]), (s, o) => {
    t.current.set(s, o), n({});
  };
};
function ot(e) {
  let n = e.type;
  return typeof n == "function" ? n(e.props) : "render" in n ? n.render(e.props) : e;
}
function B({ asChild: e, children: n }, t) {
  return e && l.isValidElement(n) ? l.cloneElement(ot(n), { ref: n.ref }, t(n.props.children)) : t(n);
}
var ut = { position: "absolute", width: "1px", height: "1px", padding: "0", margin: "-1px", overflow: "hidden", clip: "rect(0, 0, 0, 0)", whiteSpace: "nowrap", borderWidth: "0" };
const le = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w,
  {
    ref: t,
    className: _(
      "flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground",
      e
    ),
    ...n
  }
));
le.displayName = w.displayName;
const it = ({ children: e, ...n }) => /* @__PURE__ */ y.jsx(pe, { ...n, children: /* @__PURE__ */ y.jsx(pe.Content, { className: "overflow-hidden p-0 shadow-lg", children: /* @__PURE__ */ y.jsx(le, { className: "[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5", children: e }) }) }), Re = l.forwardRef(({ className: e, onClear: n, ...t }, s) => /* @__PURE__ */ y.jsxs("div", { className: "flex items-center w-full", "cmdk-input-wrapper": "", children: [
  /* @__PURE__ */ y.jsx(
    w.Input,
    {
      ref: s,
      className: _(
        "flex h-8 w-full bg-transparent py-1 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
        e
      ),
      ...t
    }
  ),
  n && /* @__PURE__ */ y.jsx(
    Ke,
    {
      size: "sm",
      onClick: () => n(),
      className: "px-1",
      variant: "ghost",
      children: /* @__PURE__ */ y.jsx(Fe, { name: "Cancel", width: 18, height: 18, strokeWidth: 1.5 })
    }
  )
] }));
Re.displayName = w.Input.displayName;
const Ce = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w.List,
  {
    ref: t,
    className: _("max-h-[300px] overflow-y-auto overflow-x-hidden", e),
    ...n
  }
));
Ce.displayName = w.List.displayName;
const Ie = l.forwardRef((e, n) => /* @__PURE__ */ y.jsx(
  w.Empty,
  {
    ref: n,
    className: "py-6 text-center text-sm",
    ...e
  }
));
Ie.displayName = w.Empty.displayName;
const $e = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w.Group,
  {
    ref: t,
    className: _(
      "overflow-hidden py-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-sm [&_[cmdk-group-heading]]:font-normal [&_[cmdk-group-heading]]:text-black-primary",
      e
    ),
    ...n
  }
));
$e.displayName = w.Group.displayName;
const Ne = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w.Separator,
  {
    ref: t,
    className: _("-mx-1 h-px bg-border", e),
    ...n
  }
));
Ne.displayName = w.Separator.displayName;
const je = l.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ y.jsx(
  w.Item,
  {
    ref: t,
    className: _(
      "relative flex cursor-default select-none items-center rounded-md min-h-9 px-2 py-2 text-sm outline-none aria-selected:bg-gray-neutral-30 aria-selected:text-accent-foreground",
      e
    ),
    ...n
  }
));
je.displayName = w.Item.displayName;
const Ae = ({
  className: e,
  ...n
}) => /* @__PURE__ */ y.jsx(
  "span",
  {
    className: _(
      "ml-auto text-xs tracking-widest text-muted-foreground",
      e
    ),
    ...n
  }
);
Ae.displayName = "CommandShortcut";
const ht = Object.assign(le, {
  Dialog: it,
  Input: Re,
  List: Ce,
  Empty: Ie,
  Group: $e,
  Separator: Ne,
  Item: je,
  Shortcut: Ae
});
export {
  ht as default
};
