import { yupResolver } from "@hookform/resolvers/yup"
import { useListener } from "polyrhythm-react";
import { useEffect, useImperativeHandle, useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { useMessagesContext } from "src/contexts/messages-context";
import { useSendAutomaticPublicMessage } from "src/hooks/use-send-automatic-public-message";
import { useUser } from "src/hooks/use-user";
import { useGetSignaturesForUserQuery } from "src/services/emailSignatureApi";
import { useGetLoanByIdQuery } from "src/services/loanApi";
import { MessageDtoExtended } from "src/services/messageApi";
import { doesTextHaveActionLinks } from "src/utils/does-text-have-action-links";
import { containsLender } from "src/utils/user/contains-lender";
import { useIsMounted } from "usehooks-ts";

import { MessageFormInputs } from "./message-compose-form.types";
import { messageValidationSchema } from "./message-compose-form.validation";

const newThreadId = 'new-thread';

interface MessagesComposeFormProps {
    initialValues?: Partial<MessageFormInputs>
}

const initialFormValues = {
    subject: '',
    body: '',
    inReplyToMessage: null,
    recipients: [],
    attachments: [],
    messageThreadId: null,
    loanId: '',
    draftedMessageId: '',
    locked: false,
    isDigestEnabled: false,
    sendPublicMessageReminder: false,
}

export const useMessagesComposeForm = (props?: MessagesComposeFormProps) => {
    const messagesContext = useMessagesContext();
    const userState = useUser();
    const {
        onToggle: sendAutomaticPublicMessageToggle,
        visible: sendAutomaticPublicMessageVisible } = useSendAutomaticPublicMessage({
            loanId: props?.initialValues?.loanId
        });
    const isMounted = useIsMounted();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { data: messageLoan } = useGetLoanByIdQuery(props.initialValues.loanId, {
        skip: !props.initialValues.loanId
    });
    const { data: emailSignatures = [] } = useGetSignaturesForUserQuery({
        userId: userState.user?.id,
        companyId: userState.company?.id
    }, {
        skip: !userState.user?.id || !userState.company?.id
    })
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        getValues,
        formState: { errors, isValid, isDirty, isSubmitting } } = useForm<MessageFormInputs>({
            resolver: yupResolver(messageValidationSchema),
            defaultValues: {
                ...initialFormValues,
                sendPublicMessageReminder: sendAutomaticPublicMessageVisible,
                ...props?.initialValues
            }
        });

    const documentIds = Object.values(messagesContext.uploadedFiles[newThreadId] ?? {})
        .filter(f => f.status === 'success')
        .map<string>(listFile => listFile.documentId)

    const [messageThreadId, recipients, isDigestEnabled, body, subject] = watch(['messageThreadId', 'recipients', 'isDigestEnabled', 'body', "subject"]);

    const { ref: bodyRef, ...otherBodyProps } = register('body');

    useImperativeHandle(bodyRef, () => messagesContext.editorRef.current)

    const onReset = () => {
        reset()
        // @ts-expect-error
        messagesContext.editorRef?.current?.reset()
        setValue('attachments', [], { shouldDirty: true, shouldValidate: true })
        setValue('recipients', [], { shouldDirty: true, shouldValidate: true })
    }

    const onFilesDrop = async (files: File[]) => {
        const documentIds = await messagesContext.onFilesDrop(files, newThreadId);
        setValue('attachments', documentIds, { shouldDirty: true, shouldValidate: true });
    }

    const onSubmit = (data: MessageFormInputs) => {
        // use subject from reply to message if it exists
        let subject = data.subject;
        const toUserIds = [];
        if (messagesContext.replyToMessage) {
            toUserIds.push(messagesContext.replyToMessage.from.id);
            subject = messagesContext.replyToMessage.subject;
        } else {
            toUserIds.push(...data.recipients);
        }

        // if logged in user is a contact, and there is no recipients , show toast message
        // and we are not replying to a message
        // and return
        if (userState.user.loggedRoleGroup === 'CONTACT' &&
            toUserIds.length === 0 &&
            !data.messageThreadId) {
            toast.error("Error. Please enter at least one recipient")
            return;
        }
        if (!sendAutomaticPublicMessageVisible && data.sendPublicMessageReminder) {
            sendAutomaticPublicMessageToggle();
        }

        messagesContext.onSendMessage({
            body: data.body,
            contextId: data.loanId,
            messageThreadId: data.messageThreadId,
            messageType: null,
            subject,
            shareOldHistory: true,
            attachments: documentIds,
            hasLinks: doesTextHaveActionLinks(data.body),
            labels: [],
            inReplyToMessage: messagesContext.replyToMessage?.id ?? null,
            toUserIds,
            toUserNames: [],
            locked: data.locked,
            toUserEmails: [],
        });
        if (data.draftedMessageId) {
            messagesContext.onDeleteDraftMessage(data.draftedMessageId);
        }
    }

    useListener(/messages\/submitted/, () => {
        onReset();
        messagesContext.onClearThreadFiles(newThreadId);
    }, {
        deps: [onReset]
    });

    const onDraft = async (data: MessageFormInputs): Promise<string> => {
        let subject = data.subject;
        const toUserIds = [];
        if (messagesContext.replyToMessage) {
            toUserIds.push(messagesContext.replyToMessage.from.id);
            subject = messagesContext.replyToMessage.subject;
        } else {
            toUserIds.push(...data.recipients);
        }

        const documentIds = Object.values(messagesContext.uploadedFiles[newThreadId] ?? {})
            .filter(f => f.status === 'success')
            .map<string>(listFile => listFile.documentId)

        const payload = {
            body: data.body,
            contextId: data.loanId,
            messageThreadId: data.messageThreadId,
            messageType: null,
            subject,
            shareOldHistory: true,
            attachments: documentIds,
            hasLinks: doesTextHaveActionLinks(data.body),
            labels: [],
            inReplyToMessage: messagesContext.replyToMessage?.id ?? null,
            toUserIds: toUserIds.filter(id => !id.includes('@')),
            toUserNames: [],
            toUserEmails: [],
            locked: data.locked
        }
        let result: MessageDtoExtended = null

        if (!data.draftedMessageId) {
            result = await messagesContext.onSendDraftMessage(payload);
        } else {
            result = await messagesContext.onUpdateDraftMessage(data.draftedMessageId, payload);
        }
        if (result && isMounted()) {
            setValue('draftedMessageId', result.id, { shouldDirty: true });
            messagesContext.onSetDraftMessage(result);
            return result.id;
        }
        return ''
    }

    const onDeleteClick = () => {
        messagesContext.onMessageComposerOpenChange(false);
        messagesContext.onClearThreadFiles(props.initialValues.messageThreadId ?? newThreadId);
        const draftId = getValues('draftedMessageId');
        // reset form
        onReset();
        if (draftId) {
            messagesContext.onDeleteDraftMessage(draftId);
        }
    }

    useEffect(() => {
        // check for uploadedFiles and set them to the form
        const uploadedFiles = Object.values(messagesContext.uploadedFiles[newThreadId] ?? {});
        register('attachments').onChange({
            target: {
                name: 'attachments',
                value: uploadedFiles
                    .filter(f => f.status === 'success')
                    .map(f => f.documentId)
            }
        });
    }, [messagesContext.uploadedFiles, props.initialValues.messageThreadId, register])

    const onPreSubmit = (data: MessageFormInputs) => {
        const isLenderAbsent = !containsLender(messageLoan.loanRoles, data.recipients);
        if (isLenderAbsent &&
            !data.subject &&
            userState.isLender &&
            !data.locked &&
            data.recipients.length > 0 &&
            !sendAutomaticPublicMessageVisible) {
            setIsDialogOpen(true);
        } else {
            onSubmit(data);
        }
    }

    const onDialogConfirm = () => {
        setIsDialogOpen(false);
        onSubmit(getValues());
    }

    const resetForm = (values: MessageFormInputs) => {
        reset({ ...initialFormValues, ...props.initialValues, recipients: [], ...values })
    }

    const emailSignature = emailSignatures.find(s => s.usedIns.includes("NEW_MESSAGES"));

    return {
        onSubmit,
        onPreSubmit,
        onDraft,
        onFileClick: messagesContext.onFileClick,
        onStageSingleFileForRename: messagesContext.onStageSingleFileForRename,
        onStageSingleFileForDelete: messagesContext.onStageSingleFileForDelete,
        onCancelSingleFile: messagesContext.onCancelSingleFile,
        onFilesDrop,
        onDeleteClick,
        uploadedFiles: Object.values(messagesContext.uploadedFiles[newThreadId] ?? {}),
        bodyProps: { ...otherBodyProps, ref: messagesContext.editorRef },
        register,
        isDirty,
        reset: onReset,
        resetForm,
        handleSubmit,
        watch,
        isSubmitting,
        loan: messageLoan,
        errors,
        replyToMessage: messagesContext.replyToMessage,
        draftMessage: messagesContext.draftMessage,
        isValid,
        recipients,
        body,
        isDigestEnabled,
        isNewThread: !messageThreadId,
        messageThreadId,
        emailSignature,
        setValue,
        getValues,
        isDialogOpen,
        setIsDialogOpen,
        onDialogConfirm,
        subject
    } as const
}