import { j as m } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as p } from "react";
import "./Accordion-Cj9fnp9V.js";
import "./Avatar-D2CKq5wD.js";
import "./Badge-C0bj4DFU.js";
import "./Button-DhTfdjdw.js";
import "./DropdownMenu-HQ_2FMOb.js";
import "./Icon-XRrXcH6L.js";
import "./Input-vl7Xx2ol.js";
import "./Label-BKL8LTmX.js";
import { T as n } from "./Text-DOFuHqUo.js";
import "./Tooltip-ae2E5kx6.js";
import "./Stack-9Jnn74Nm.js";
import "./Switch-BGmOPDvx.js";
import "./Toast-D4ae9-ZY.js";
import "./Toggle-DH_2vm0p.js";
import { H as f } from "./HighlightText.component-BZNRT9Y9.js";
const g = (i) => i ? e(i.givenName) && e(i.familyName) ? `${i.givenName} ${i.familyName}` : e(i.givenName) ? i.givenName : e(i.familyName) ? i.familyName : "name" in i && e(i.name) ? i.name : "email" in i && e(i.email) ? i.email : "emailAddress" in i && e(i.emailAddress) ? i.emailAddress : "" : "", e = (i) => i != null && i.trim().length > 0 && i !== "null" && i !== "null null", z = p((i, a) => {
  const { user: t, isMe: r, highlight: o = "", ...l } = i, s = r && t.givenName ? String(t.givenName) : g(t);
  return /* @__PURE__ */ m.jsxs(n, { as: "div", ref: a, ...l, children: [
    /* @__PURE__ */ m.jsx(f, { text: s, highlightText: o }),
    " ",
    r && /* @__PURE__ */ m.jsx(n, { as: "span", size: "inherit", variant: "secondary", children: "(Me)" })
  ] });
});
export {
  z as U
};
