export enum KeyStorage {
    KanbanSetting = 'kanbanSetting',
    KanbanFilters = 'kanbanFilters',
    PackageFilterState = 'packageFilterState',
    PackageFormElementsVisibility = 'packageFormElementsVisibility',
    TimezoneMode = 'timezoneMode',
    SendAutomaticPublicMessage = 'sendAutomaticPublicMessage',
    TableColumns = 'tableColumns',
    LenderShoeBoxTeamFilterList = 'lenderShoeBoxTeamFilter',
    PackageColumnWidth = 'packageColumnWidth',
    PinnedBorrowerPortalLenders = 'pinnedBorrowerPortalLenders',
    HiddenBorrowerPortalLenders = 'hiddenBorrowerPortalLenders',
    LoanViewType = 'loanViewType',
    DoNotShowTourAgain = 'doNotShowTourAgain',
    CompletedProductTourSteps = 'completedProductTourSteps',
    LoanOverviewAccordions = 'loanOverviewAccordions',
    OneDriveDefaultEditPlatform = 'oneDriveDefaultEditPlatform',
}