export const ShoeBoxQueryParam = {
    documentId: 'did',
    documentName: 'dname',
    providerType: 'ptype',
}

export const QUERY_DOCUMENT_PREVIEW_ID = 'documentPreviewId'
export const QUERY_ELEMENT_DOCUMENT_PREVIEW_ID = 'elementDocumentPreviewId'
export const QUERY_SHOEBOX_ITEM_PREVIEW_ID = 'shoeBoxItemPreviewId'
export const QUERY_ANONYMOUS_SESSION_ID = 'anonSessionId'
export const QUERY_PARAM_VIEWER_ACTIONS_DISABLED = 'viewerActionsDisabled'
export const QUERY_PARAM_USER_SHOEBOX_USER_ID = 'userShoeboxUserId'
export const QUERY_PARAM_USER_SHOEBOX_OPEN = 'userShoeboxOpen'
export const QUERY_PARAM_LOAN_SHOE_BOX_OPEN = 'loanShoeBoxOpen'