export const QUERY_PARAM_FORM_PREVIEW_ONLY_ELEMENT_ID = 'previewOnlyFormElementId';

export const QUERY_PARAM_FORM_ELEMENT_ID = 'formElementId';

export const QUERY_PARAM_PREVIEW_FORM_ELEMENT_ID = 'previewFormElementId';

export const QUERY_PARAM_LOAN_ID = 'loanId';

export const QUERY_PARAM_COMPANY_ID = 'companyId';

export const LOAN_TABS = { DETAILS: 'DETAILS', PACKAGE: 'PACKAGE' } as const;