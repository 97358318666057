import { useState } from 'react';
import { toast } from 'react-toastify';
import { useUpdateSignatureMutation } from 'src/services/emailSignatureApi';

import { SignatureEditDialogProps } from "./signature-edit-dialog.types";

export const useSignatureEditDoalogState = (props: SignatureEditDialogProps) => {
    const [updateSignature] = useUpdateSignatureMutation();
    const [signature, setSignature] = useState(props.signature);
    const onEditorChange = (args: { target: { name: string, value: string } }) => {
        setSignature({
            ...signature,
            body: args.target.value
        });
    };

    const onSaveSignature = async () => {
        try {
            await updateSignature(signature).unwrap();
            props.onDialogOpenChange(false);
        } catch (e) {
            toast.error('Failed to save signature');
        }
    };

    return {
        onEditorChange,
        onSaveSignature,
        value: signature.body
    } as const;
};